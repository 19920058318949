<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">智能网联</h1>
        <p data-caption-delay="100" class="wow fadeInRight">智能网联自动化测试解决方案</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <div class="sw-tab-title" ref="tab" :class="tabFixed ? getTabFixedClass() : ''">
      <div class="container">
        <el-tabs v-model="activeProduct" @tab-click="handleTabClick">
          <el-tab-pane label="车身域HIL测试系统" name="1">车身域HIL测试系统</el-tab-pane>
          <el-tab-pane label="汽车OTA测试系统" name="2">汽车OTA测试系统</el-tab-pane>
          <el-tab-pane label="汽车V2X测试系统" name="3">汽车V2X测试系统</el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div :class="tabFixed?'solution-tab-fixed':''">
      <div v-if="activeProduct === '1'">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <p class="intro-detail">车身域HIL仿真测试平台可以进行车身控制器的功能开发验证，既高效又便利，不仅缩短了控制器的开发流程，进一步加快车型的研发生产周期，并且可以结合客户控制器实际功能需求，提供满足测试需求的测试系统解决方案。</p>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections solution-functions wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div
              class="all-function"
              @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
              @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
            >
              <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(item, index) in domainFunctionList" :key="index">
                  <div class="card-item">
                    <el-card class="solution-function-card" shadow="hover">
                      <div class="card-icon">
                        <i :class=" item.icon "></i>
                      </div>
                      <div class="card-text">
                        <p>{{ item.content }}</p>
                      </div>
                    </el-card>
                  </div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </section>
        <section class="domainHIL-solution-components wow fadeInLeft">
          <div class="container" style="height: 500px;">
            <div class="normal-title">核心组件</div>
            <div class="solution-components">
              <div class="components-content">
                <img class="domain-hil-components-img" src="../../../assets/images/solution-domainHIL-components.png" alt="">
                <div class="components-text">
                  <div>
                    <h1>硬件组成</h1>
                    <ol>
                      <li>主体形式：标准19英寸机柜</li>
                      <li>供电分配及保护单元：电源总开关，过流保护，急停操作按钮等</li>
                      <li>程控电源：1.5kW~3kW</li>
                      <li>实时信号仿真模块：</li>
                      <el-table
                        :data="componentsTableData"
                        :show-header="false"
                        class="components-table"
                      >
                        <el-table-column prop="firCol" label=""/>
                        <el-table-column prop="secCol" label=""/>
                        <el-table-column prop="thiCol" label=""/>
                      </el-table>
                      <li>控制器测试接口：专业快速连接测试组件</li>
                    </ol>
                  </div>
                  <div style="margin-top: 35px">
                    <h1>软件组成</h1>
                    <ol>
                      <li>测试用例开发软件：Vector vTESTstudio</li>
                      <li>测试执行软件：Vector CANoe</li>
                      <li>实时仿真模型：Matlab Simulink</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="network-solution-system-picture wow fadeInRight">
          <div class="container">
            <div class="normal-title">系统图片</div>
            <div class="all-system-picture">
              <div class="system-picture">
                <img src="../../../assets/images/solution-domainHIL-system-pic.png" alt="">
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '2'">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <div class="intro-text" style="width: 100%;">
                    <p>在网联化和软件定义汽车两大趋势下，汽车OTA（远程升级）受到汽车业界越来越多的重视，OTA升级持续给客户提供新的功能，不断增强用户体验，同时也可以修复用户反馈的软件问题，提升OEM的售后服务。目前OTA远程升级技术已成为众多OEM的标配。由于OTA升级可能会带来潜在的风险（特别是包含ECU固件更新的FOTA升级），针对OTA系统的测试过程就尤为重要。针对OTA测试，东信创智可提供HIL测试方案，覆盖OTA整个流程的测试。</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div class="all-function">
              <ul class="priority-list" style="margin-top: 20px">
                <li>云平台自动化操作，包括后台登录、任务包创建、任务包审批及下发等</li>
                <li>通过ADB指令对车机进行操作，满足OTA过程中的人机交互模拟</li>
                <li>Vector专业的网络测试工具链保证</li>
                <li>便捷的测试操作及管理UI，支持车型、VIN号、任务包等必要测试流程信息管理</li>
                <li>完整的测试覆盖度，包括正向流程测试、异常流程测试、前置条件不满足、网络异常、供电异常等交互测试</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="electric-solution-components network-solution-components wow fadeInLeft">
          <div class="container">
            <div class="normal-title">核心组件</div>
            <div class="solution-components">
              <el-row class="components-content" :gutter="40">
                <el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
                  <img src="../../../assets/images/solution-network-components.png" alt="">
                </el-col>
                <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
                  <div class="components-text">
                    <div>
                      <h1>硬件组成</h1>
                      <ol>
                        <li>标准19英寸机柜</li>
                        <li>自动化控制板卡：VT System</li>
                        <li>高性能工控机</li>
                        <li>无线综测仪-根据需求可选配</li>
                        <li>矢量信号发生器-根据需求可选配</li>
                      </ol>
                    </div>
                    <div style="margin-top: 50px">
                      <h1>软件组成</h1>
                      <ol>
                        <li>测试开发及执行软件：CANoe</li>
                        <li>测试管理软件：StormEye</li>
                      </ol>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="solution-application wow fadeInRight">
          <div class="container">
            <div class="normal-title">应用领域</div>
            <div class="all-application">
              <div class="application-content">
                <div class="application-icon">
                  <img src="../../../assets/images/application-picture.svg" alt="">
                </div>
                <div class="application-text">
                  <p>OTA自动化测试</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="dyna-product-system-picture wow fadeInLeft">
          <div class="container">
            <div class="normal-title">系统图片</div>
            <div class="all-system-picture">
              <div class="system-picture">
                <img src="../../../assets/images/solution-ota-system-pic.png" alt="">
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '3'">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <div class="intro-text" style="width: 100%;">
                    <p>在国家“车路协同”的大趋势下，汽车作为物联网的一类终端产品，网联功能的应用目前也广泛受到行业内的关注，并已推出多款具备网联功能的量产车型。V2X测试系统是东信创智面向车联网（V2X/C-V2X）领域的一款测试仿真产品，面向技术前沿的主机厂、Tier-1、科研机构等企业，针对 V2X 技术和 OBU 部件，采用工况仿真的方式，构建接近真实的交通场景，闭环测试 OBU 的 V2X 应用能力，满足主机厂对V2X OBU功能的测试要求。本测试系统支持V2X产品开发与验证过程中，多个环节的测试。</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections v2x-solution-function wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <el-row>
              <el-col :span="24">
                <div class="all-function">
                  <p class="priority-title">优势特点：</p>
                  <ul class="priority-list" style="margin-top: 20px">
                    <li>面向V2X领域的专业测试系统，支持功能场景测试，解决实车测试难点</li>
                    <li>支持HIL、SIL、MIL、PIL等多种测试模式，并可扩展VIL测试</li>
                    <li>具备量产型OBU应用经验，提供测试用例集（包括上千条测试用例）</li>
                    <li>兼容集成行业主流仪表设备，如R&S仪表等，并支持LTE-V阵列或OBU辅测机模式</li>
                    <li>友好的UI界面，操作简单方便，一键自动化测试模式</li>
                    <li>测试报告和评价体系完备，支持自定义报告模板，支持自定义场景评价方法</li>
                    <li>3D场景显示，兼容成熟虚拟场景仿真软件，支持多屏展示和专业的演示功能</li>
                    <li>单一场景软件中支持多DUT设备，每个DUT设备可具有独立的GNSS和车载总线仿真数据通道</li>
                  </ul>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <p class="priority-title" style="margin-top: 0">系统功能：</p>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                <el-table
                  style="font-size: 14px"
                  :data="functionTableData1"
                  :header-cell-style="{background:'#296CA8',color:'#FFFFFF'}"
                  border
                  size="small"
                >
                  <el-table-column prop="project" label="项目" width="150"/>
                  <el-table-column prop="description" label="描述" />
                </el-table>
                <el-table
                  style="font-size: 14px;margin-top: -1px"
                  :data="functionTableData2"
                  :span-method="functionArraySpanMethod"
                  :show-header="false"
                  border
                  size="small"
                >
                  <el-table-column
                    prop="project"
                    label=""
                    width="150"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="description"
                    label="">
                  </el-table-column>
                </el-table>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                <div class="function-picture" :style="isMobile ? 'margin-left:0' : 'margin-top: 50px;margin-left:15px'">
                  <img src="../../../assets/images/solution-v2x-function-pic.png" alt="">
                </div>
              </el-col>
            </el-row>
          </div>
        </section>
        <section class="ethernet-solution-components wow fadeInLeft">
          <div class="container">
            <div class="normal-title">核心组件</div>
            <div class="solution-components">
              <div class="components-content">
                <el-row>
                  <el-col :span="24">
                    <div class="components-table" style="height: 360px">
                      <el-table
                        style="font-size: 14px"
                        :data="componentsTableData1"
                        :span-method="componentsArraySpanMethod1"
                        :header-cell-style="{background:'#296CA8',color:'#FFFFFF'}"
                        border
                        size="small"
                      >
                        <el-table-column prop="type" width="120" label="分类" />
                        <el-table-column prop="project" label="项目" />
                        <el-table-column prop="description" label="描述" />
                      </el-table>
                      <el-table
                        style="font-size: 14px;margin-top: -1px"
                        :data="componentsTableData2"
                        :span-method="componentsArraySpanMethod2"
                        :show-header="false"
                        border
                        size="small"
                      >
                        <el-table-column prop="type" width="120" label="" />
                        <el-table-column prop="project" label="" />
                        <el-table-column prop="description" label="" />
                      </el-table>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </section>
        <section class="network-solution-system-picture v2x-solution-system-picture wow fadeInRight">
          <div class="container">
            <div class="normal-title">系统图片</div>
            <div class="all-system-picture">
              <div class="system-picture">
                <img src="../../../assets/images/solution-v2x-system-pic.png" alt="" style="width: 100%">
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from '@/components/MainNav'
import mainFooter from '@/components/MainFooter'
import bzInfo from '@/components/BzInfo'
export default {
  name: "NetworkConnection",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data(){
    return {
      activeIndex: '2-4',
      tabFixed: false,
      activeProduct: '1',
      domainFunctionList:[
        {
          icon: "ri-roadster-fill",
          content: "图形化用例开发编辑环境，含有丰富的可拖拽函数控件"
        },
        {
          icon: "ri-roadster-fill",
          content: "可以依据测试条件自动遍历生成测试脚本，保证测试覆盖率可达100%"
        },
        {
          icon: "ri-roadster-fill",
          content: "支持状态机编译开发以及多种常用的测试方法论，如边界值法等"
        },
        {
          icon: "ri-roadster-fill",
          content: "通用硬件接口，支持硬件资源复用，可扩展多类型控制器"
        },
        {
          icon: "ri-roadster-fill",
          content: "支持控制器：BCM、PEPS、AC、DSCU、VCU等"
        },
      ],
      swiperOption: {
        spaceBetween: 20, // 图片之间的间距
        centeredSlides: false, // 居中还是从图1开始
        slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
        breakpointsInverse: true,
        breakpoints: {
          //当宽度大于等于1100
          1100: {
            slidesPerView: 4,
            spaceBetween: 10
          }
        },
        notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
        // loop: true, // 循环吗
        initialSlide: 0, // 从第几个开始
        autoplay: {
          delay: 5000, // 等5秒下一个
          disableOnInteraction: false // 中间滑动一下，取消自动吗？
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }, // 下按钮
        speed: 800, // 滑动时候动画的速度
        paginationClickable: true, // 下面按钮让点吗
        navigation: {
          prevEl: '.swiper-button-prev', // 左侧按钮
          nextEl: '.swiper-button-next' // 右侧按钮
        },
        // effect: 'fade', // 渐入效果
        watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
        watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
        // autoHeight: true  // 图片高度自适应
      },
      componentsTableData: [
        {
          firCol:"(1) 电源信号",
          secCol:"(2) 开关信号",
          thiCol:"(3) 电子负载仿真"
        },
        {
          firCol:"(4) 模拟I/O信号",
          secCol:"(5) 数字I/O信号",
          thiCol:"(6) 特殊激励信号"
        },
        {
          firCol:"(7) 网络接口卡",
          secCol:"(8) 实时处理器",
          thiCol:"(9) SENT传感器型号"
        },
      ],
      functionTableData1: [
        {
          project: "国标一期功能测试",
          description: "V2X一期应用场景标准规范中的16个应用进行场景仿真及预警功能测试",
        },
        {
          project: "国标二期功能测试",
          description: "V2X二期应用场景标准规范中的13个应用进行场景仿真及预警功能测试",
        },
      ],
      functionTableData2: [
        {
          project: "协议一致性测试",
          description: "《基于LTE的车联网无线通信技术-网络层测试方法》",
        },
        {
          project: "协议一致性测试",
          description: "《基于LTE的车联网无线通信技术-消息层测试方法》",
        },
        {
          project: "协议一致性测试",
          description: "《基于LTE的车联网无线通信技术-安全层测试方法》",
        }
      ],
      componentsTableData1: [
        {
          type: "硬件",
          project: "V2X信号仿真器",
          description: "模拟单车及多车的V2X信息",
        },
        {
          type: "硬件",
          project: "GNSS信号仿真器",
          description: "模拟单车及多车的导航定位信息",
        },
        {
          type: "硬件",
          project: "车载总线仿真接口",
          description: "模拟CAN和车载以太网通信信息",
        },
        {
          type: "硬件",
          project: "DUT供电模拟设备",
          description: "模拟被测设备电源，提供基础工作环境",
        },
        {
          type: "硬件",
          project: "软件运行系统",
          description: "控制主机，运行系统操作及核心软件",
        }
      ],
      componentsTableData2: [
        {
          type: "软件",
          project: "虚拟场景仿真软件",
          description: "提供虚拟场景搭建基础开发及运行环境",
        },
        {
          type: "软件",
          project: "测试管理软件",
          description: "系统UI，实现整体测试的流程参数管理",
        },
        {
          type: "软件",
          project: "车载通信仿真软件",
          description: "提供车载通信开发及运行环境",
        }
      ],
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
      isMobile: false,
    }
  },
  mounted() {
    new this.$wow.WOW().init();
    document.title = "智能网联 - 解决方案 - 东信创智";
    window.addEventListener("scroll", this.handleScroll);
    this.isMobile = document.documentElement.clientWidth < 1200
    window.onresize = () => {
      return (() => {
        this.isMobile = document.documentElement.clientWidth < 1200;
      })();
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
    handleScroll() {
      if (document.documentElement.clientWidth > 1100) {
        let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
        if (top < 0 && top > -630) {
          this.tabFixed = !this.tabFixed;
        } else if (document.documentElement.scrollTop > 630) {
          this.tabFixed = true;
        }
      } else {
        let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
        if (top < 0 && top > -250) {
          this.tabFixed = !this.tabFixed;
        } else if (document.documentElement.scrollTop > 250) {
          this.tabFixed = true;
        }
      }
    },
    getTabFixedClass() {
      if (document.documentElement.clientWidth < 1100) {
        return 'sw-tab-title-mobile-fixed'
      } else {
        return 'sw-tab-title-fixed'
      }
    },
    handleTabClick() {
      if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
        document.documentElement.scrollTop = 630
      } else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop > 250) {
        document.documentElement.scrollTop = 250
      }
    },
    // eslint-disable-next-line no-unused-vars
    functionArraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 && ( rowIndex === 0 || rowIndex === 1 || rowIndex === 2)) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    componentsArraySpanMethod1({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 && (rowIndex === 0 || rowIndex === 1 || rowIndex === 2 || rowIndex === 3 || rowIndex === 4)) {
        if (rowIndex % 5 === 0) {
          return {
            rowspan: 5,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    componentsArraySpanMethod2({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 && (rowIndex === 0 || rowIndex === 1 || rowIndex === 2)) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
